import React, { useEffect } from "react";
import CardData from "./Data1";
import AOS from "aos";
import "aos/dist/aos.css";

let line = {
    width: '8rem',
    height: '5px',
    background: 'linear-gradient(to right, #fe8464 0%, #fe6e9a 50%, #fe8464 100%)',
}
let card_container = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center'
}
function Section() {
     useEffect(()=>{
     AOS.init({duration: 1000});
    },[])
    return <>
        <div className="container my-5">
            <div style={card_container}>
                <div style={line}></div>
                <h1 className="py-3">Meet Our  Creative Team <br /> Members</h1>
            </div>

            <div className="row">
                {
                    CardData.map((val) => {
                        return (
                            <div className="col-sm-12 col-md-4 col-lg-4">
                                <div className="card text-center shadow">
                                    <img src={val.imgsrc} className="card-img-top" data-aos="zoom-in" alt="..." />
                                    <div className="card-body">
                                        <h5 className="card-title">{val.name}</h5>
                                        <p className="card-text">{val.post}</p>
                                        {/* <a href="#" className="btn blog-btn">Read more</a> */}
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    </>
}
export default Section