import React,{useEffect} from "react";
// import img from '../src/images/employe2.png'
import Employee from "../src/images/employe4.png"
import AOS from "aos";
import "aos/dist/aos.css";

let btn = {
    background: 'linear-gradient(to right, #fe8464 0%, #fe6e9a 50%, #fe8464 100%)',
    color: '#fff',
    padding: '10px 30px'
}
let line = {
    width: '8rem',
    height: '5px',
    background: 'linear-gradient(to right, #fe8464 0%, #fe6e9a 50%, #fe8464 100%)',
}
let p_color = {
    color: '#6A6972'
}


function Comp1() {
    useEffect(()=>{
     AOS.init({duration: 2000});
    },[])
    return <>
        <div className="container" >
        <div className="section-title text-center pb-40">
                <div className="line m-auto mt-5 "></div>
                <h1 className="py-3 fw-bold">Team Members</h1>
            </div>
            <div className="row text-center" style={{border:"1px solid gainsboro", padding:"10px", boxShadow: "0 6px 10px rgba(0, 0, 0, 0.3)"}}>
                <div className="col-sm-12 col-md-6 col-lg-6 card1">
                    <div className="first-text-block" data-aos="slide-left">
                        <h2 className="Member-name fw-bold" >Valerie Webb</h2>

                        <p>Lorem Ipsum, sometimes referred to as 'lipsum', is the placeholder text used in design when creating content. It helps designers plan out where the content will sit, without needing to wait for the content to be written and approved. It originally comes from a Latin text, but to today's reader, it's seen as gibberish.</p>
                    </div>
                </div>

                <div className="col-sm-12 col-md-6 col-lg-6 ">
                    <div className="first-img-block" data-aos="slide-right">
                        <img src={Employee} className="img-fluid"  />
                    </div>
                </div>
            </div>
        </div>

        <div className="container">
            <div className="row second-row my-5 text-center" style={{border:"1px solid gainsboro", padding:"10px", boxShadow: "0 6px 10px rgba(0, 0, 0, 0.3)"}}>

                <div className="col-sm-12 col-md-6 col-lg-6">
                    <div className="second-img-block" data-aos="slide-left">
                        <img src={Employee} className="img-fluid" />
                    </div>
                </div>

                <div className="col-sm-12 col-md-6 col-lg-6">
                    <div className="second-text-block" data-aos="slide-right">
                    <h2 className="Member-name fw-bold">Valerie Webb</h2>
                        <p>Lorem Ipsum, sometimes referred to as 'lipsum', is the placeholder text used in design when creating content. It helps designers plan out where the content will sit, without needing to wait for the content to be written and approved. It originally comes from a Latin text, but to today's reader, it's seen as gibberish.</p>
                    </div>
                </div>
            </div>
        </div>

        <div className="container">
            <div className="row text-center" style={{border:"1px solid gainsboro", padding:"10px", boxShadow: "0 6px 10px rgba(0, 0, 0, 0.3)"}}>

                <div className="col-sm-12 col-md-6 col-lg-6">
                    <div className="third-text-block" data-aos="slide-left">
                    <h2 className="Member-name fw-bold" >Valerie Webb</h2>
                        <p>Lorem Ipsum, sometimes referred to as 'lipsum', is the placeholder text used in design when creating content. It helps designers plan out where the content will sit, without needing to wait for the content to be written and approved. It originally comes from a Latin text, but to today's reader, it's seen as gibberish.</p>
                    </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6">
                    <div className="third-img-block" data-aos="slide-right">
                        <img src={Employee} className="img-fluid" />
                    </div>
                </div>
            </div>
        </div>
    </>
}
export default Comp1
