const CardData = [
    {
        imgsrc: 'https://media.istockphoto.com/id/1347494044/photo/smiling-young-asian-man-checking-electronic-banking-on-his-smartphone-as-he-received.webp?b=1&s=170667a&w=0&k=20&c=M-hCwapuCbKwN3uXRP5r7f2vAjxt5l5p8QHjoKJGsro=',
        name: 'Lorem Ipsum',
        post: 'Founder & CEO'
    },
    {
        imgsrc: 'https://media.istockphoto.com/id/1347494044/photo/smiling-young-asian-man-checking-electronic-banking-on-his-smartphone-as-he-received.webp?b=1&s=170667a&w=0&k=20&c=M-hCwapuCbKwN3uXRP5r7f2vAjxt5l5p8QHjoKJGsro=',
        name: 'Lorem Ipsum',
        post: 'Founder & CEO'
    }, {
        imgsrc: 'https://media.istockphoto.com/id/1347494044/photo/smiling-young-asian-man-checking-electronic-banking-on-his-smartphone-as-he-received.webp?b=1&s=170667a&w=0&k=20&c=M-hCwapuCbKwN3uXRP5r7f2vAjxt5l5p8QHjoKJGsro=',
        name: 'Lorem Ipsum',
        post: 'Founder & CEO'
    }
]
export default CardData