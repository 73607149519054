import React from 'react'
import Img1 from "../src/images/product1.png";
import Navbar from './Navbar';
import Footer from './Footer';

const Product1 = () => {
  return (
    <>
    <Navbar/>
            <div className="container" style={{paddingTop: "5%"}}>
            <h1 className='p2-head '>TeleDoX</h1>
                <div className="row mt-5">
                    <div className="col-lg-6 col-md-6 col-sm-6 ">
                        <img src={Img1} className='product2-img' alt="Product.png" height="450px" width="400px" />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 card2 pt-5">
                        <div className="content d-flex">
                            <div className='Number1-one ' >
                                <h3 className='num1 p-2'>01</h3>
                            </div>
                            <div className="content-title ps-5">
                                <h2 className='fw-bolder text-dark'>Vital Monitoring</h2>
                                <p className='p-content text-justify '>Monitors vitals such as BP, SpO2, Body temperature, PR etc.</p>
                            </div>
                        </div>
                        <hr />
                        <div className="content d-flex pt-3">
                            <div className='Number1-one '>
                                <h3 className='num1'>02</h3>
                            </div>
                            <div className="content-title ps-5">
                                <h2 className='fw-bolder text-dark'>Pathalogy</h2>
                                <p className='p-content text-justify '>Can detect pathalogies for dengue, malaria, typhoid, TB etc </p>
                            </div>
                        </div>
                        <hr />
                        <div className="content d-flex pt-3">
                            <div className='Number1-one '>
                                <h3 className='num1'>03</h3>
                            </div>
                            <div className="content-title ps-5 pb-4">
                                <h2 className='fw-bolder text-dark'>Telemedicine</h2>
                                <p className='p-content text-justify'>Connects with doctor in realtime data sharing </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
  )
}

export default Product1