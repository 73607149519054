import React from 'react'

const Navbar = () => {
    return (
        <>
            <div className="med-header navbar-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10">
                            <nav className=" navbar-expand-lg" >
                                <a className="navbar-brand" href="index.html">
                                    {/* <img src={Imgs} alt="Logo" /> */}
                                    <h3 className="Med-logo">MedXai</h3>
                                </a>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Navbar