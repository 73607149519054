import React from 'react'
import Img1 from "../src/images/product2.png";
import Footer from './Footer';
import Navbar from './Navbar';

const Product2 = () => {
    return (
        <>
        <Navbar/>
            <div className="container" style={{paddingTop: "5%"}}>
            <h1 className='p2-head '>RetCam</h1>
                <div className="row mt-5">
                    <div className="col-lg-6 col-md-6 col-sm-6 justif-center">
                        <img src={Img1} className='product2-img' alt="Product.png" height="400px" width="400px" />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 card2 pt-5">
                        <div className="content d-flex">
                            <div className='Number1-one '>
                                <h3 className='num1 p-2'>01</h3>
                            </div>
                            <div className="content-title ps-5">
                                <h2 className='fw-bolder text-dark'>Fundus</h2>
                                <p className='fs-5 text-justify'>Captures fundus image of an eye</p>
                            </div>
                        </div>
                        <hr />
                        <div className="content d-flex pt-3">
                            <div className='Number1-one '>
                                <h3 className='num1'>02</h3>
                            </div>
                            <div className="content-title ps-5">
                                <h2 className='fw-bolder text-dark'>AI analysis</h2>
                                <p className='fs-5 text-justify  '>Detect cataracts,glaucoma and other diseases.</p>
                            </div>
                        </div>
                        <hr />
                        <div className="content d-flex pt-3">
                            <div className='Number1-one '>
                                <h3 className='num1'>03</h3>
                            </div>
                            <div className="content-title ps-5 pb-4">
                                <h2 className='fw-bolder text-dark'>Patient EMR</h2>
                                <p className='fs-5 text-justify '>Fundus and patient information is stored in the EMR</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
        
    )
}

export default Product2