import React from "react"
import { useEffect, useState } from "react";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim";
import HeaderImg from "../src/images/web2.png";
import Product from "../src/images/product1.png";
import Product2 from "../src/images/product2.png";
import Product3 from "../src/images/product3.png";
import { Link } from 'react-router-dom';

function Headandcard() {
    const [init, setInit] = useState(false);
    useEffect(() => {
        initParticlesEngine(async (engine) => {
            await loadSlim(engine);
        }).then(() => {
            setInit(true);
        });
    }, []);

    const particlesLoaded = (container) => {
        console.log(container);
    };
    return <>

        <section id="features" className="services-area ">
            {init && <Particles
                id="tsparticles"
                particlesLoaded={particlesLoaded}
                options={{
                    fpsLimit: 60,
                    interactivity: {
                        events: {
                            onClick: {
                                enable: true,
                                mode: "push",
                            },
                            onHover: {
                                enable: true,
                                mode: "repulse",
                            },
                            resize: true,
                        },
                        modes: {
                            push: {
                                quantity: 3,
                            },
                            repulse: {
                                distance: 100,
                                duration: 0.4,
                            },
                        },
                    },
                    particles: {
                        color: {
                            value: "#ffffff",
                        },
                        links: {
                            color: "#ffffff",
                            distance: 100,
                            enable: false,
                            opacity: 0.5,
                            width: 1,
                        },
                        move: {
                            direction: "top",
                            enable: true,
                            outModes: {
                                default: "bounce",
                            },
                            random: false,
                            speed: 2,
                            straight: false,
                        },
                        number: {
                            density: {
                                enable: true,
                                area: 2762.016745712954,
                            },
                            value: 15,
                        },
                        opacity: {
                            value: 0.1,
                        },
                        shape: {
                            type: "circle",
                        },
                        size: {
                            value: { min: 20, max: 30 },
                        },
                    },
                    detectRetina: true,
                }}
            />
            }
            <div className="container">
                <header className=" header-area">
                    <div
                        id="home"
                        className="header-hero bg_cover ">
                        <div className="  text-center">
                            <div className="row justify-content-center pt-100">
                                {/* <div className="Medxai-heading"> */}
                                <div className="col-sm-12 col-md-6 col-lg-6 ">
                                    <img src={HeaderImg} className=" img-fluid" width="500px" height="500px" alt="" />
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-6 pt-5">
                                    <div className="font-clr">
                                        <h1 className="display">MedXai</h1>
                                        <h1 className="display-2 fw-bold">Doc Bot Pvt Ltd</h1>
                                        <h4 className="display">WWW.MEDXAI.IN</h4>
                                    </div>
                                </div>
                                {/* </div> */}
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div
                                        className="header-hero-image text-center wow fadeIn"
                                        data-wow-duration="1.3s"
                                        data-wow-delay="1.4s"
                                    >
                                        {/* <img src={Imgsss} alt="hero" /> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div id="particles-1" className="particles"></div> */}
                    </div>

                </header>

                <div className="row justify-content-center">
                    <div className="col-lg-12">
                        <div className="section-title text-center pb-40 ">
                            <div className="line m-auto"></div>
                            <h3 className="title fw-bold text-dark" style={{ marginBottom: "11%" }}>
                                Products
                            </h3>
                        </div>
                    </div>
                </div>

                <div className="row justify-content-center">
                    <div className="products col-lg-4 col-md-6 col-sm-12 my-3">
                        <div
                            className="single-services text-center mt-30 wow fadeIn position-relative pb-1"
                            data-wow-duration="1s"
                            data-wow-delay="0.2s"
                        >
                            <div className="row ">
                                <div className="col-lg-6">
                                    <h1 className="Number-one py-3"> 01</h1>
                                </div>

                                <div className="col-lg-6">
                                    <img src={Product} className="product-img" alt="" />
                                </div>
                                <div className=" col-12 services-content mt-0" >
                                    <h4 className="services-title">
                                        <Link to="/product">TeleDox</Link>
                                    </h4>
                                    <h6 className="text">
                                        A health kiosk with all vital
                                        measurement, pathalogies and
                                        telemedicine
                                    </h6>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="products col-lg-4 col-md-6 col-sm-12 my-3">
                        <div
                            className="single-services text-center mt-30 wow fadeIn position-relative"
                            data-wow-duration="1s"
                            data-wow-delay="0.2s"
                        >

                            <div className="row ">
                                <div className="col-lg-6">
                                    <h1 className="Number-one py-3 "> 02</h1>
                                </div>

                                <div className="col-lg-6">
                                    <img src={Product2} className="product-img1" alt="" />
                                </div>
                                <div className="col-12 services-content">
                                    <h4 className="services-title ">
                                        <Link to="/product2">RetCam</Link>
                                    </h4>
                                    <h6 className="text">
                                        A portable fundus capturing device
                                        which is AI enabled
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="products col-lg-4 col-md-6 col-sm-12 my-3">
                        <div
                            className="single-services text-center mt-30 wow fadeIn position-relative"
                            data-wow-duration="1s"
                            data-wow-delay="0.2s"
                        >

                            <div className="row ">
                                <div className="col-lg-6">
                                    <h1 className="Number-one py-3 "> 03</h1>
                                </div>
                                <div className="col-lg-6">

                                    <img src={Product3} className="product-img2" alt="" />

                                </div>
                                <div className="col-12 services-content   ">
                                    <h4 className="services-title">
                                        <Link to="/product3">DocBot</Link>
                                    </h4>
                                    <h6 className="text">
                                        A sleak, portable multivital monitoring
                                        device

                                        telemedicine
                                    </h6>

                                </div>

                            </div>


                        </div>

                    </div>

                </div>


            </div>
            <div id="particles-3"></div>
        </section>

    </>
}

export default Headandcard