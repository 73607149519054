import React, { useEffect } from "react"
// import Img from '../src/images/logo-2.png'
import Contact from "../src/images/contactpart.png"
import "aos/dist/aos.css";
import Aos from "aos";


let line = {
  width: '8rem',
  height: '5px',
  background: 'linear-gradient(to right, #fe8464 0%, #fe6e9a 50%, #fe8464 100%)',
 
}

let card_container = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  textAlign: 'center'
}


function Footer() {
  useEffect(()=>{
   Aos.init({duration: 2000});
  },[])
    return <>
    <div id="footer" className="footer-area ">
      <div className="container ">
        <div
          className="subscribe-area wow fadeIn"
          data-wow-duration="1s"
          data-wow-delay="0.5s"
        >
          <div className="row">
          <div style={card_container}>
          <div style={line} ></div>
          <h1 className="subscribe-title fw-bold text-center ">
                 CONTACTS
                </h1>
                </div>
            <div className="col-lg-6">
              <div className="subscribe-content mt-45 text-center">
                <img src={Contact} className="Contact-img" data-aos="slide-left" alt="" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="subscribe-form mt-50">
                <form action="#" className="" >
                  <input className="form-section mb-3" type="email" placeholder="Email Address"  required />
                  <input className="form-section mb-3" type="phone" placeholder="Phone Number" maxLength={10} required />
                  <input className="form-section mb-3" type="text" placeholder="Mailing Address" required />
                </form>
              </div>
              <button className="main-btn">Submit</button>
            </div>
          </div>
        </div>
        <div className="footer-widget pb-100">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-8">
              <div
                className="footer-about mt-50 wow fadeIn"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                    <a className="logo" href="javascript:void(0)"> 
                    {/* <img src={Img} alt="Logo" /> */}
                    <h3 className="text-white">MedXAI</h3>
                    </a>
                <p className="text">
                  Lorem ipsum dolor sit amet consetetur sadipscing elitr,
                  sederfs diam nonumy eirmod tempor invidunt ut labore et dolore
                  magna aliquyam.
                </p>
                <ul className="social">
                  <li>
                    <a href="javascript:void(0)">
                      <i className="lni lni-facebook-filled"> </i>
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0)">
                      <i className="lni lni-twitter-filled"> </i>
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0)">
                      <i className="lni lni-instagram-filled"> </i>
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0)">
                      <i className="lni lni-linkedin-original"> </i>
                    </a>
                  </li>
                </ul>
              </div>
              
            </div>
            <div className="col-lg-5 col-md-7 col-sm-12">
              <div className="footer-link d-flex mt-50 justify-content-sm-between">
                <div
                  className="link-wrapper wow fadeIn"
                  data-wow-duration="1s"
                  data-wow-delay="0.4s"
                >
                  <div className="footer-title">
                    <h4 className="title">Quick Link</h4>
                  </div>
                  <ul className="link">
                    <li><a href="javascript:void(0)">Road Map</a></li>
                    <li><a href="javascript:void(0)">Privacy Policy</a></li>
                    <li><a href="javascript:void(0)">Refund Policy</a></li>
                    <li><a href="javascript:void(0)">Terms of Service</a></li>
                    <li><a href="javascript:void(0)">Pricing</a></li>
                  </ul>
                </div>
                <div
                  className="link-wrapper wow fadeIn"
                  data-wow-duration="1s"
                  data-wow-delay="0.6s"
                >
                  <div className="footer-title">
                    <h4 className="title">Resources</h4>
                  </div>
                  <ul className="link">
                    <li><a href="javascript:void(0)">Home</a></li>
                    <li><a href="javascript:void(0)">Page</a></li>
                    <li><a href="javascript:void(0)">Portfolio</a></li>
                    <li><a href="javascript:void(0)">Blog</a></li>
                    <li><a href="javascript:void(0)">Contact</a></li>
                  </ul>
                </div>
              
              </div>
             
            </div>
            <div className="col-lg-3 col-md-5 col-sm-12">
              <div
                className="footer-contact mt-50 wow fadeIn"
                data-wow-duration="1s"
                data-wow-delay="0.8s"
              >
                <div className="footer-title">
                  <h4 className="title">Contact Us</h4>
                </div>
                <ul className="contact">
                  <li>+809272561823</li>
                  <li>info@gmail.com</li>
                  <li>www.yourweb.com</li>
                  <li>
                    123 Stree New York City , United <br />
                    States Of America 750.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-copyright">
          <div className="row">
            <div className="col-lg-12">
              <div className="copyright d-sm-flex justify-content-between">
                <div className="copyright-content">
                  <p className="text text-center">
                    @copyright 2024 medxai
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="particles-2"></div>
    </div>

    <a href="#" className="back-to-top"> <i className="lni lni-chevron-up"> </i> </a>
   
    {/* <script src="assets/js/bootstrap.bundle.min.js"></script>
    <script src="assets/js/wow.min.js"></script>
    <script src="assets/js/glightbox.min.js"></script>
    <script src="assets/js/count-up.min.js"></script>
    <script src="assets/js/particles.min.js"></script>
    <script src="assets/js/main.js"></script> */}
   </>
}

export default Footer;