import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './basicstyle.css';
import './bootstrap.min.css';
import 'aos/dist/aos.css';

import Headandcard from './Headandcard.jsx';
import Comp1 from './Comp1.jsx';
import Section from './Section.jsx';
import Footer from './Footer.jsx';
import Product1 from './Product1.jsx';
import Product2 from './Product2.jsx';
import Product3 from './Product3.jsx';
import Navbar from './Navbar.jsx';
// import Contact from './Contact.jsx';

ReactDOM.render(
  <Router>
    <Routes>
      <Route
        path="/"
        element={
          <>
          <Navbar/>
            <Headandcard />
           
            <Comp1 />
            <Section />
            {/* <Contact/> */}
            <Footer />
          </>
        }
      />
      <Route path="/product" element={<Product1 />} />
      <Route path="/product2" element={<Product2 />} />
      <Route path="/product3" element={<Product3 />} />
    </Routes>
  </Router>,
  document.getElementById('root')
);
